import { FaBars } from "react-icons/fa";
import { NavLink  } from 'react-router-dom';
import { useEffect, useState } from "react";
import {  useSelector  ,} from "react-redux";
import { RiLogoutCircleLine } from "react-icons/ri";
import { FaHome } from "react-icons/fa";
import { CiBank } from "react-icons/ci";
import { GiTreeBranch } from "react-icons/gi";
import {  Nav  } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { PiUsersFill } from "react-icons/pi";
import { MdAccountBalanceWallet } from "react-icons/md";
import { FaServicestack } from "react-icons/fa6";
import { GiReceiveMoney } from "react-icons/gi";
import { GiPayMoney } from "react-icons/gi";
import { MdOutlineRememberMe } from "react-icons/md";
import { FaUserPlus } from "react-icons/fa";
import { FaUserGroup } from "react-icons/fa6";
import { MdOutlineElectricalServices } from "react-icons/md";
import { GiCash } from "react-icons/gi";
import { SiVault } from "react-icons/si";
import { FaVault } from "react-icons/fa6";
import { FaCashRegister } from "react-icons/fa";
import startccu from '../images/startccu.jpg'
import bell from '../images/bell.svg'
import profile from '../images/profile-img.jpg'
import arrowright from '../images/box-arrow-right.svg'
import gear from '../images/gear.svg'
import person from '../images/person.svg'
import chat from '../images/chat-left-text.svg'
import people from '../images/people-fill.svg'
import { UserMessageListAction } from "../actions/approvalAction";
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component'
import { MdOutlineEdit } from "react-icons/md";
import { approvalUserMessActions } from "../actions/approvalAction";
import { ApproveCreateCustomerAccounAction } from "../actions/customerAccountAction";
import { APPROVAL_MESSAGE_SAVE_REST } from "../constants/approvalConstants";
import { ErrorMessage ,successMessage } from "./Message";
import Loader from '../Components/Loader';
import { SaveAutoAction } from "../actions/customerAccountAction";
import moment from 'moment'
import { StopAutoServicerActions ,deleteEditSigInfo} from "../actions/approvalAction";
import { approveAutoServicerActions } from "../actions/approvalAction";
import { getAaccountSignatoryDetailsEditAction ,UsateSigAccounAction ,UsateSigAccounActionsig1  ,UsateSigAccounActionsig2 ,UsateSigAccounActionsig3 ,updateAccountName} from "../actions/customerAccountAction";
import { SaveAccountTransaction } from "../actions/customerAccountAction";
import { vaultUpdateActions } from "../actions/vaultAction";
import { FaMoneyBillWheat } from "react-icons/fa6";
import { AiFillDingtalkCircle } from "react-icons/ai";
import { FaMoneyBillWave } from "react-icons/fa6";
import { AiFillEdit } from "react-icons/ai";

function Menu({children}) {
  const Messagecolumns =[
   
    {
     name:'Request' ,
     selector:row=>row.message_sum,
     width: "270px" 
     ,wrap:true
    },


    {
       name:'Acc.' ,
       selector:row=>row.account_name,
       width: "100px" 
       ,wrap:true
      },

    {
     name:'Number',
     selector:row=>row.account_number ,
     width: "100px" 
     ,wrap:true ,
     sortable:true
    },

    {
       name:'Type',
       selector:row=>row.acc_ty ,
       width: "100px" 
       ,wrap:true ,
       sortable:true
      },
   
      {
       name:'Select' , cell:row=> <button onClick={()=>selete_Account_details(row)}  className='btt'> <MdOutlineEdit className='preview-btn'/> </button>,
       width:"70px"
      }  ,

]

  const [Request ,setRequest]=useState(false)
  const [messageRequest , setmessageRequest]=useState()
  const [accountnumber , setaccountnumber]=useState()
  const [accountname , setaccountname]=useState()
  const [stentby , setstentby]=useState()
  const [stentno , setstentno]=useState()
  const [trans_code , settrans_code]=useState()
  const [table_pk ,settable_pk]=useState()
  const [selectedrow ,setselectedrow]=useState()
  const [otherinfo ,setotherinfo]=useState()
  const [message ,setmessage]=useState()
  
  

const aproveAccountMessage=()=>{

  let approval_status=true
  let message_sum = messageRequest +'- Request Approved'
  let save_aproval=true
  let edit_aproval=false
  let edit_by=''
  let level=userProfileInfo.Authorisation_Level
  
  dispatch(approvalUserMessActions({trans_code:trans_code ,
    approval_status ,
    message_sum
    
}))


dispatch(ApproveCreateCustomerAccounAction({trans_code:table_pk  ,
  save_aproval ,
  edit_aproval,
  edit_by,
  level
}))


}


const aproveSmsRequst=()=>{

  let approval_status=true
  let message_sum = messageRequest +'- Request Approved'
  let level=userProfileInfo.Authorisation_Level
  let dat=moment().format('YYYY-MM-DD')

  console.log(accountnumber)
  console.log(accountname)
  console.log(selectedrow.acc_ty)
  console.log(userInfo.username)
  console.log(selectedrow.tel)

 


  dispatch(SaveAutoAction(accountnumber,accountname ,'-' ,'-' ,selectedrow.acc_ty, "-", "SMS alert on transactions" ,  0 , dat,  level, userInfo.username ,selectedrow.tel ,'-'))
  
  dispatch(approvalUserMessActions({trans_code:trans_code ,
    approval_status ,
    message_sum
    
 }))




}


const aproveEmailRequst=()=>{

  let approval_status=true
  let message_sum = messageRequest +'- Request Approved'
  let level=userProfileInfo.Authorisation_Level
  let dat=moment().format('YYYY-MM-DD')

  console.log(accountnumber)
  console.log(accountname)
  console.log(selectedrow.acc_ty)
  console.log(userInfo.username)
  console.log(selectedrow.tel)

 


  dispatch(SaveAutoAction(accountnumber,accountname ,'-' ,'-' ,selectedrow.acc_ty, "-", "Email alert on transactions" ,  0 , dat,  level, userInfo.username ,'-' ,selectedrow.email))
  
  dispatch(approvalUserMessActions({trans_code:trans_code ,
    approval_status ,
    message_sum
    
 }))




}

const aproveStopServiceRequst=()=>{

  let approval_status=true
  let message_sum = messageRequest +'- Request Approved'

  console.log(accountnumber)
  console.log(accountname)
  console.log(selectedrow.acc_ty)
  console.log(userInfo.username)
  console.log(selectedrow.tel)

 
  dispatch(StopAutoServicerActions({trans_code:selectedrow.other_pk
}))
  
  dispatch(approvalUserMessActions({trans_code:trans_code ,
    approval_status ,
    message_sum
    
 }))




}


const changeSMSEmail=()=>{

  let approval_status=true
  let message_sum = messageRequest +'- Request Approved'
  let save_aproval=true
  let edit_aproval=false
  let edit_by=userInfo.username
  let level=userProfileInfo.Authorisation_Level
 

  let tel=selectedrow.tel
  let email=selectedrow.email
  
 
dispatch(approveAutoServicerActions({trans_code:selectedrow.other_pk ,
  save_aproval ,
  edit_aproval,
  edit_by,
  level ,
  tel ,
  email
}))

  dispatch(approvalUserMessActions({trans_code:trans_code ,
    approval_status ,
    message_sum
    
 }))




}


const updateCustomerAccunt=()=>{

  let approval_status=true
  let message_sum = messageRequest +'- Request Approved'
  
  let signature= sigEditInfo.assig  
  let picone=sigEditInfo.pic
  let card_ty= sigEditInfo.card
  let card_Id=sigEditInfo.card_no
  


dispatch(UsateSigAccounAction({trans_code:selectedrow.table_pk ,
  signature ,
  picone,
  card_ty ,
  card_Id
}))


  dispatch(approvalUserMessActions({trans_code:trans_code ,
    approval_status ,
    message_sum
    
 }))

 dispatch(deleteEditSigInfo({trans_code:sigEditInfo.trans_code}))




}


const updateCustomerAccuntcor1=()=>{

  let approval_status=true
  let message_sum = messageRequest +'- Request Approved'
  
  let na1= sigEditInfo.card 
  let tel1=sigEditInfo.card_no
  let card_ty= sigEditInfo.assig
  let card_Id=sigEditInfo.assig2
  let picone= sigEditInfo.pic
  let signature=sigEditInfo.pic2
  


dispatch(UsateSigAccounActionsig1({trans_code:selectedrow.table_pk ,
  na1 ,
  tel1,
  card_ty,
  card_Id,
  picone,
  signature
}))


  dispatch(approvalUserMessActions({trans_code:trans_code ,
    approval_status ,
    message_sum
    
 }))

 dispatch(deleteEditSigInfo({trans_code:sigEditInfo.trans_code}))




}


const updateCustomerAccuntcor2=()=>{

  let approval_status=true
  let message_sum = messageRequest +'- Request Approved'
  
  //console.log(sigEditInfo)
  let na2= sigEditInfo.card 
  let tel2=sigEditInfo.card_no
  let card_ty_2= sigEditInfo.assig
  let card_Id_2=sigEditInfo.assig2
  let pictwo= sigEditInfo.pic
  let signature2=sigEditInfo.pic2
  


dispatch(UsateSigAccounActionsig2({trans_code:selectedrow.table_pk ,
  na2 ,
  tel2,
  card_ty_2,
  card_Id_2,
  pictwo,
  signature2
}))


  dispatch(approvalUserMessActions({trans_code:trans_code ,
    approval_status ,
    message_sum
    
 }))

 dispatch(deleteEditSigInfo({trans_code:sigEditInfo.trans_code}))




}

const updateCustomerAccuntcor3=()=>{

  let approval_status=true
  let message_sum = messageRequest +'- Request Approved'
  
  //console.log(sigEditInfo)
  let na3= sigEditInfo.card 
  let tel3=sigEditInfo.card_no
  let card_ty_3= sigEditInfo.assig
  let card_Id_3=sigEditInfo.assig2
  let picthree= sigEditInfo.pic
  let signature3=sigEditInfo.pic2
  


dispatch(UsateSigAccounActionsig3({trans_code:selectedrow.table_pk ,
  na3 ,
  tel3,
  card_ty_3,
  card_Id_3,
  signature3,
  picthree

}))


  dispatch(approvalUserMessActions({trans_code:trans_code ,
    approval_status ,
    message_sum
    
 }))

 dispatch(deleteEditSigInfo({trans_code:sigEditInfo.trans_code}))
}



const activateVault=()=>{

  let save_aproval=1
  let approval_status=true
  let message_sum = messageRequest +'- Request Approved'
  
  dispatch(vaultUpdateActions({code:selectedrow.table_pk ,
    save_aproval 
  }))

  
  dispatch(approvalUserMessActions({trans_code:trans_code ,
    approval_status ,
    message_sum
    
 }))

}

const inActivateVault=()=>{

  let save_aproval=0
  let approval_status=true
  let message_sum = messageRequest +'- Request Approved'
  
  dispatch(vaultUpdateActions({code:selectedrow.table_pk ,
    save_aproval 
  }))

  
  dispatch(approvalUserMessActions({trans_code:trans_code ,
    approval_status ,
    message_sum
    
 }))

}

const updateAccountname=()=>{

  let approval_status=true
  let message_sum = messageRequest +'- Request Approved'
  
  //console.log(sigEditInfo)
  let account_name= sigEditInfo.card 
  
  
dispatch(updateAccountName({trans_code:selectedrow.table_pk ,
  account_name 
  
}))


  dispatch(approvalUserMessActions({trans_code:trans_code ,
    approval_status ,
    message_sum
    
 }))

 dispatch(deleteEditSigInfo({trans_code:sigEditInfo.trans_code}))
}


// Deposit /Withdrawal Error
const updateDepositWithdrawal=()=>{

  let approval_status=true
  let message_sum = messageRequest +'- Request Approved'
  
  let dat=moment().format('MMMM Do YYYY, h:mm:ss a')
  
  
  let account_name=accountname
  let account_number=accountnumber
  let product_name=selectedrow.acc_ty

  let cr=0.00
  let dr=0.00
  
 if(selectedrow.title=='Deposit'){
  
  cr=0.00
  dr=selectedrow.tel

 }else if(selectedrow.title=='Withdrawal'){

  dr=0.00
  cr=selectedrow.tel
  
 }
 

  let branch =userProfileInfo.branch
  let des ='Transaction Reversal ' + otherinfo
  let dat2 =moment().format('YYYY-MM-DD')
  let user=stentby
  let vault_id='-'

  
  dispatch(SaveAccountTransaction(cr ,dat ,dr ,dr ,branch ,account_number,des ,dat2 ,account_name,product_name ,user ,'Transaction Reversal','','Transaction Reversal','Transaction Reversal',vault_id 
    ,0 , 0 ,0 ,0 ,0 ,0 ,0 ,0, 0 , 0 ,0 ,0 , 0 ,'-' ))


  dispatch(approvalUserMessActions({trans_code:trans_code ,
    approval_status ,
    message_sum
    
 }))

 
 
}



const DisapproveRequst=()=>{

  let approval_status=true
  let message_sum = 'Request Disapprove'
  let save_aproval=true
  let edit_aproval=false
  let edit_by=userInfo.username
  let level=userProfileInfo.Authorisation_Level
  let dat=moment().format('YYYY-MM-DD')

  let tel=selectedrow.tel
  let email=selectedrow.email
  

dispatch(approvalUserMessActions({trans_code:trans_code ,
    approval_status ,
    message_sum
 }))

 if(sigEditInfo.trans_code){

  dispatch(deleteEditSigInfo({trans_code:sigEditInfo.trans_code}))

 }

 if(selectedrow.other_pk !=''){

  dispatch(StopAutoServicerActions({trans_code:selectedrow.other_pk
  }))

 }
 
   
 
}


const selete_Account_details =(row)=> {

  setmessageRequest(row.message_sum)
  setaccountnumber(row.account_number)
  setaccountname(row.account_name)
  setstentby(row.sent_by)
  setstentno(row.dat2)
  settrans_code(row.trans_code)
  settable_pk(row.table_pk)
  setselectedrow(row)
  setmessage(row.message)
  setotherinfo(row.message+ ' (' + row.title +')' + ' Of An Amount Of GH  ' + row.tel)


  if(row.message_sum=='Request For Account Signatory Change' || row.message_sum=='Request For Account Signatory(1) Change' 
    || row.message_sum=='Request For Account Signatory(2) Change' || row.message_sum=='Request For Account Signatory(3) Change'
     || row.message_sum=='Request change of Account Name'){

    dispatch(getAaccountSignatoryDetailsEditAction(row.table_pk , approval_status))

  }
}

const selete_Account=(usermessage)=>{

  setRequest(true)

  setmessageRequest(usermessage.message_sum)
  setaccountnumber(usermessage.account_number)
  setaccountname(usermessage.account_name)
  setstentby(usermessage.sent_by)
  setstentno(usermessage.dat2)
  settrans_code(usermessage.trans_code)
  settable_pk(usermessage.table_pk)
  setselectedrow(usermessage)
  setmessage(usermessage.message)
  setotherinfo(usermessage.message+ ' (' + usermessage.title +')' + ' Of An Amount Of GH  ' + usermessage.tel)

  if(usermessage.message_sum=='Request For Account Signatory Change' || usermessage.message_sum=='Request For Account Signatory(1) Change' 
    || usermessage.message_sum=='Request For Account Signatory(2) Change' || usermessage.message_sum=='Request For Account Signatory(3) Change'
    || usermessage.message_sum=='Request change of Account Name'){

    dispatch(getAaccountSignatoryDetailsEditAction(usermessage.table_pk , approval_status))

  }
}


const openRequest=() =>{
  setRequest(true)
}

const closeRequest=() =>{
  setRequest(false)

}


  let approval_status=0
  
  const dispatch=useDispatch()

    const [isOpen ,setIsOpen] = useState(false)

    const toggle =()=>setIsOpen (!isOpen)

    const userLogin =useSelector(state=>state.userLogin)
    const{userInfo}=userLogin

    const userProfileReducer = useSelector(state => state.userProfileReducer)
    const { userProfileInfo }=userProfileReducer

    const userMenuItems = useSelector(state => state.userMenuItems)
    const { MenuItems }=userMenuItems

    const user_Message_Reducer = useSelector(state => state.user_Message_Reducer)
    const { usermessage ,usermessageLoading }=user_Message_Reducer

    const save_Message_Reducer = useSelector(state => state.save_Message_Reducer)
    const { messagesaveLoading ,messagesave ,savesuccess }=save_Message_Reducer


    const CorporateAccountsignatoryDetailsReducer = useSelector(state => state.CorporateAccountsignatoryDetailsReducer)
    const { CorporateAccountsignatoryDetails ,CorporateAccountsignatoryDetailsLoading }=CorporateAccountsignatoryDetailsReducer

    const CustomerAccountDetailsReducer = useSelector(state => state. CustomerAccountDetailsReducer)
    const {CustomerAccountDetails ,CustomerAccountDetailsLoading}= CustomerAccountDetailsReducer

    const [sigEditInfo , setsigEditInfo]=useState([])

  useEffect(() => {
    if(CorporateAccountsignatoryDetails.length >=1){
      setsigEditInfo(CorporateAccountsignatoryDetails[0])
    }
    
  },[CorporateAccountsignatoryDetailsReducer])
    


    useEffect(()=>{

      if(savesuccess){
      
      successMessage('Request Loogged Successful')

      dispatch({type:APPROVAL_MESSAGE_SAVE_REST})

      dispatch(UserMessageListAction(userInfo.username ,approval_status))

      clear();

      }
  

    },[save_Message_Reducer])
    
    const clear=()=>{

      setmessageRequest('')
      setaccountnumber('')
      setaccountname('')
      setstentby('')
      setstentno('')
      settrans_code('')
      settable_pk('')

    }

    const [messageCount ,setMessageCount] =useState(0)


    const meunItem=[
      
        {
          path:'/',
          name: 'SYSTEM HOME',
          icon:<FaHome/>,
        },
  
        {
          path:'/bank',
          name: 'BANK INFORMATION',
          icon:<CiBank/>,
        },
  
        {
          path:'/bankbranch',
          name: 'BRANCH INFORMATION',
          icon:<GiTreeBranch/>,
        },
    
        {
          path:'/Product_Services',
          name: 'OUR PRODUCTS ',
          icon:<MdAccountBalanceWallet />,
        },
  
        {
          path:'/servicer',
          name: 'ACCOUNT SERVICES ',
          icon:<FaServicestack />,
        },
  
        
        {
          path:'/loanProduct',
          name: 'LOAN PRODUCTS ',
          icon:<GiReceiveMoney />,
        },
    
        {
          path:'/investment',
          name: 'INVESTMENT PRODUCTS ',
          icon:<GiPayMoney />,
        },
  
        
        {
          path:'/LoginRegistration',
          name: 'SYSTEM USER',
          icon:<PiUsersFill/>,
        },
        
        {
          path:'/logout',
          name: 'LOGOUT',
          icon:<RiLogoutCircleLine/>,
        },
      ]

      useEffect(() => {
        const interval = setInterval(() => {
          //setCounter((prevCounter) => prevCounter + 1);
          dispatch(UserMessageListAction(userInfo.username ,approval_status))

        }, 360000);
    
        return () => clearInterval(interval);
      }, []);


  useEffect(()=>{

    
    if(usermessage && usermessage.length >0){

      setMessageCount(usermessage.length)

    }else {
      setMessageCount(0)
    }

  },[user_Message_Reducer])
  

  return (
    <div className="Content backg">


    <div className="">

    <header id="header" class="header  d-flex align-items-center">

<div class="d-flex align-items-center justify-content-between">
  <a href="index.html" class="logo d-flex align-items-center">
    <span className="d-none d-lg-block">Bank Soft</span>
  </a>

</div>



<nav class="header-nav ms-auto">
  <ul class="d-flex align-items-center">

    

    <li class="nav-item dropdown">

      <a class="nav-link nav-icon" href="#" data-bs-toggle="dropdown">
        
        <img src={bell} className="message" />
        <span class="badge bg-primary badge-number">4</span>
      </a>

      <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications">
        <li class="dropdown-header">
          You have 4 new notifications
          <a href="#"><span class="badge rounded-pill bg-primary p-2 ms-2">View all</span></a>
        </li>
        <li>
          <hr class="dropdown-divider"/>
        </li>

        <li class="notification-item">
          <i class="bi bi-exclamation-circle text-warning"></i>
          <div>
            <h4>Lorem Ipsum</h4>
            <p>Quae dolorem earum veritatis oditseno</p>
            <p>30 min. ago</p>
          </div>
        </li>

        <li>
          <hr class="dropdown-divider"/>
        </li>

        <li class="notification-item">
          <i class="bi bi-x-circle text-danger"></i>
          <div>
            <h4>Atque rerum nesciunt</h4>
            <p>Quae dolorem earum veritatis oditseno</p>
            <p>1 hr. ago</p>
          </div>
        </li>

        <li>
          <hr class="dropdown-divider"/>
        </li>

        <li class="notification-item">
          <i class="bi bi-check-circle text-success"></i>
          <div>
            <h4>Sit rerum fuga</h4>
            <p>Quae dolorem earum veritatis oditseno</p>
            <p>2 hrs. ago</p>
          </div>
        </li>

        <li>
          <hr class="dropdown-divider"/>
        </li>

        <li class="notification-item">
          <i class="bi bi-info-circle text-primary"></i>
          <div>
            <h4>Dicta reprehenderit</h4>
            <p>Quae dolorem earum veritatis oditseno</p>
            <p>4 hrs. ago</p>
          </div>
        </li>

        <li>
          <hr class="dropdown-divider"/>
        </li>
        <li class="dropdown-footer">
          <a href="#">Show all notifications</a>
        </li>

      </ul>

    </li>

    <li class="nav-item dropdown">

      <a class="nav-link nav-icon" href="#" data-bs-toggle="dropdown">
        <img src={chat} />
        <span class="badge bg-success badge-number">{messageCount}</span>
      </a>

      <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow messages">
        <li class="dropdown-header">
          {

          Number(messageCount) > 0 ?

          'You have '+ messageCount +' unattended Approval Request'

          :'You have No Approval Request'
          
          }

          {

          Number(messageCount) > 0 ?

          <a onClick={openRequest} ><span class="badge rounded-pill bg-primary p-2 ms-2">View all</span></a>

          :''

          }
          
         
        </li>
        <li>
          <hr class="dropdown-divider"/>
        </li>

        {
          usermessage && usermessage.length >=1 &&
            <>
              <li class="message-item">
              <a  onClick={()=>selete_Account(usermessage[0])} >
                <img src={usermessage[0].pic} alt="" class="rounded-circle"/>
                <div>
                  <h4>{usermessage[0].sent_by}</h4>
                  <p>{usermessage[0].message_sum +' For Account  '+usermessage[0].account_name +' And Number: ' + usermessage[0].account_number }</p>
                    <p>{usermessage[0].dat2}</p>
                </div>
              </a>
            </li>
            <li>
              <hr class="dropdown-divider"/>
            </li>
            </>
          
        }


{
         usermessage && usermessage.length >=2 &&
            <>
              <li class="message-item">
              <a  onClick={()=>selete_Account(usermessage[1])} >
                <img src={usermessage[1].pic} alt="" class="rounded-circle"/>
                <div>
                <h4>{usermessage[1].sent_by}</h4>
                <p>{usermessage[1].message_sum}</p>
                  <p>{usermessage[1].dat2}</p>
                </div>
              </a>
            </li>
            <li>
              <hr class="dropdown-divider"/>
            </li>
            </>
          
        }

{
         usermessage &&  usermessage.length >=3 &&
            <>
              <li class="message-item">
              <a  onClick={()=>selete_Account(usermessage[2])} >
                <img src={usermessage[2].pic} alt="" class="rounded-circle"/>
                <div>
                <h4>{usermessage[2].sent_by}</h4>
                <p>{usermessage[2].message_sum}</p>
                <p>{usermessage[2].dat2}</p>
                </div>
              </a>
            </li>
            <li>
              <hr class="dropdown-divider"/>
            </li>
            </>
          
        }

      </ul>

    </li>

    <li class="nav-item dropdown pe-3">

      <a className="nav-link nav-profile d-flex align-items-center pe-0"  data-bs-toggle="dropdown">
      {
    
    userProfileInfo ? 
    userProfileInfo.image=='' ?  <img src={profile} alt="Profile" className="dd-toggle__img"/> :  
    <img src={userProfileInfo.image} alt="Profile" className="dd-toggle__img"/>

    :''
      }
        <span className="d-none d-md-block dropdown-toggle ps-2 text-light">{userInfo.first_name}</span>
      </a>

      <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
        <li className="dropdown-header">
          <h6>{userInfo.first_name}</h6>
          <span>{userInfo.last_name}</span>
        </li>
        <li>
          <hr className="dropdown-divider"/>
        </li> 

        <li>
          <a className="dropdown-item d-flex align-items-center" >
            <img src={person} />
            <LinkContainer to='/'>
              <Nav.Link className="ps-2" >My Profile</Nav.Link>
              </LinkContainer>
          </a>
        </li>
        <li>
          <hr className="dropdown-divider"/>
        </li>

        <li>
          <a className="dropdown-item d-flex align-items-center">
           
            <span></span>

            <img src={gear}/>
            <LinkContainer to='/'>
              <Nav.Link className="ps-2" >Account Settings</Nav.Link>
              </LinkContainer>
          </a>
        </li>

        {
      userInfo.last_name=='System Administrator' ? 
      
      <>

     

        <li>
          <hr className="dropdown-divider"/>
        </li>
        

        <li>
          <a className="dropdown-item d-flex align-items-center" Link="#">
            <img src={people} className="bi bi-box-arrow-right"/>
            <LinkContainer to='/LoginRegistration'>
              <Nav.Link className="ps-2" >System  Users</Nav.Link>
              </LinkContainer>
          </a>
        </li>
        
      
      </>
     
      
      :''
        
        }

        
        
        <li>
          <hr className="dropdown-divider"/>
        </li>

        <li>
          <a className="dropdown-item d-flex align-items-center" Link="#">
            <img src={arrowright} className="bi bi-box-arrow-right"/>
            <LinkContainer to='/logout'>
              <Nav.Link className="ps-2" >logout</Nav.Link>
              </LinkContainer>
          </a>
        </li>

      </ul>
    </li>

  </ul>
</nav>

</header>
    
    
    </div>
    
        
    <div className='containere'>
    
    
        <div style={{width:isOpen ? "200px": "50px"}} className='sidebar'>
         
          <div className='top_section'>
            <h1 style={{display:isOpen ? "block": "none"}} className='logo'><img width={400}  height={400} src={startccu} alt="Profile" className=" img-fluid"/></h1>
            <div style={{marginLeft:isOpen ? "10px" :"0px"}} className='bars'>
              <FaBars onClick={toggle}/>
            </div>
          </div> 
         
          {
            MenuItems ? 
            MenuItems.map((item,index)=>(
              <NavLink to={item.path} key={index} className='link' activeclassName='active'>
                {
                  item.icon==='<CiBank/>' ? <CiBank/>
                  
                  : item.icon==='<GiTreeBranch/>' ? <GiTreeBranch/>
                  
                  : item.icon==='<MdAccountBalanceWallet />'? <MdAccountBalanceWallet />

                  : item.icon==='<FaServicestack />'? <FaServicestack />

                  : item.icon==='<GiReceiveMoney />'? <GiReceiveMoney />

                  : item.icon==='<MdOutlineRememberMe/>'? <MdOutlineRememberMe/>

                  : item.icon==='<FaUserGroup/>'? <FaUserGroup/>

                  : item.icon==='<FaUserPlus/>'? <FaUserPlus/>

                  : item.icon==='<GiPayMoney />'? <GiPayMoney />

                  : item.icon==='<MdOutlineElectricalServices/>'? <MdOutlineElectricalServices/>

                  : item.icon==='<GiCash/>'? <GiCash/>

                  : item.icon==='<SiVault/>'? <SiVault/>

                  : item.icon==='<FaVault/>'? <FaVault/>

                  : item.icon==='<FaCashRegister/>'? <FaCashRegister />

                  : item.icon==='<FaMoneyBillWheat />'? <FaMoneyBillWheat />

                  : item.icon==='<AiFillDingtalkCircle />'? <AiFillDingtalkCircle />

                  : item.icon==='<FaMoneyBillWave/>'? <FaMoneyBillWave />

                  : item.icon==='<AiFillEdit />'? <AiFillEdit />

                  : '' 

                  
                }
                <div style={{display:isOpen ? "block":"none"}} className='link_text'>{item.name}</div>
              </NavLink>
            ))
             : ''
          }
    
    
        </div>
        <main>{children}</main>
    
    </div>


    

      <Modal size="lg" show={Request} onHide={closeRequest} dialogClassName="my-modal"  backdrop="static" keyboard={false}>                                                                                                                                 
                    <Modal.Header closeButton>
                    <Modal.Title>Message</Modal.Title>
                    </Modal.Header>
                    <Modal.Body >

                    {messagesaveLoading && <Loader/>}
                    
                    <div className="row">
                    <div className="col-md-6">

                    <input type='text' name='product Name' required className='form-control search__input mt-3 mb-3' placeholder='Search'  />

                    <div className="card">
                    <DataTable className='rdt_Table' columns={Messagecolumns}  data={usermessage=='' ? usermessage : usermessage}   
                         
                         selectableRowsHighlight 
                          highlightOnHover
                          fixedHeader
                          pagination 
                          paginationPerPage={10}  
                          responsive={true}
                      >

                    </DataTable>

                    </div>
                    </div>

                    

                    <div className="col-md-6">

                    <h5 className='mb-3'  for='Utility'>Selected Request Information</h5>

                      <label className='mt-2 lable'  for='Utility'><samp className='text-danger'>* </samp>Request</label>
                      <input name="Name" type="text" className="form-control mb-2" value={messageRequest} />

                      <label className= 'lable'  for='Utility'><samp className='text-danger'>* </samp>Number</label>
                      <input name="Name" type="text" className="form-control mb-2" value={accountnumber} />

                      <label className= 'lable'><samp className='text-danger'>* </samp>Name</label>
                      <input name="Name" type="text" className="form-control mb-2" value={accountname} />

                      <label className= 'lable'><samp className='text-danger'>* </samp>Sent On</label>
                      <input name="Name" type="text" className="form-control mb-2" value={stentno} />

                      <label className= 'lable' ><samp className='text-danger'>* </samp>Sent by</label>
                      <input name="Name" type="text" className="form-control mb-2" value={stentby} />

                    
                      { selectedrow &&

                      selectedrow.table=='account_transaction' &&  <>
                        
                        <label className= 'lable' ><samp className='text-danger'>* </samp>Other Details</label>
                        <input name="Name" type="text" className="form-control mb-2" value={otherinfo} />

                        <div className="col-md-6">
                       <button onClick={updateDepositWithdrawal} className="show_info_btn mt-4">Approved Request</button>
                        </div>
                        <div className="col-md-6">
                        <button onClick={DisapproveRequst} className="show_error_btn mt-4 ">Disapprove Request</button>

                        </div>

                            </>}
                     



                     
                          {messageRequest=='Request For Account Opening Authorization' ? 
                         
                         <>
                          <div className="col-md-6">
                            <button onClick={aproveAccountMessage} className="show_info_btn mt-4">Approved Request</button>
                        </div>
                        <div className="col-md-6">
                        <button onClick={DisapproveRequst} className="show_error_btn mt-4 ">Disapprove Request</button>

                        </div>
                         </>
                          
                          : messageRequest=='Request For SMS alert on transactions' ? 
                          
                          <>
                          <div className="col-md-6">
                            <button onClick={aproveSmsRequst} className="show_info_btn mt-4">Approved Request</button>
                        </div>
                        <div className="col-md-6">
                        <button onClick={DisapproveRequst} className="show_error_btn mt-4 ">Disapprove Request</button>

                        </div>
                         </>
                          
                          
                          :messageRequest=='Request For Email alert on transactions' ? 
                          <>
                          <div className="col-md-6">
                            <button onClick={aproveEmailRequst} className="show_info_btn mt-4">Approved Request</button>
                        </div>
                        <div className="col-md-6">
                        <button onClick={DisapproveRequst} className="show_error_btn mt-4 ">Disapprove Request</button>

                        </div>
                         </>
                         
                         :messageRequest=='Request For SMS alert stop on acctount transactions' ? 
                         <>
                         <div className="col-md-6">
                           <button onClick={aproveStopServiceRequst} className="show_info_btn mt-4">Approved Request</button>
                       </div>
                       <div className="col-md-6">
                       <button onClick={DisapproveRequst} className="show_error_btn mt-4 ">Disapprove Request</button>

                       </div>
                        </>
                        
                         :messageRequest=='Request For Email alert stop on acctount transactions' ? 
                        <>
                        <div className="col-md-6">
                          <button onClick={aproveStopServiceRequst} className="show_info_btn mt-4">Approved Request</button>
                      </div>
                      <div className="col-md-6">
                      <button onClick={DisapproveRequst} className="show_error_btn mt-4 ">Disapprove Request</button>

                      </div>
                       </>
                       
                       :messageRequest=='Request change of SMS alert number' ? 
                       <>
                       <div className="col-md-6">
                         <button onClick={changeSMSEmail} className="show_info_btn mt-4">Approved Request</button>
                     </div>
                     <div className="col-md-6">
                     <button onClick={DisapproveRequst} className="show_error_btn mt-4 ">Disapprove Request</button>

                     </div>
                      </>
                      
                      :messageRequest=='Request change of email alert Address' ? 
                       <>
                       <div className="col-md-6">
                         <button onClick={changeSMSEmail} className="show_info_btn mt-4">Approved Request</button>
                     </div>
                     <div className="col-md-6">
                     <button onClick={DisapproveRequst} className="show_error_btn mt-4 ">Disapprove Request</button>

                     </div>
                      </>
                      
                      :messageRequest=='Request For Account Signatory Change' ? 
                       <>
                       <div className="col-md-6">
                         <button onClick={updateCustomerAccunt} className="show_info_btn mt-4">Approved Request</button>
                     </div>
                     <div className="col-md-6">
                     <button onClick={DisapproveRequst} className="show_error_btn mt-4 ">Disapprove Request</button>

                     </div>
                      </>  
                      :messageRequest=='Request For Account Signatory(1) Change' ? 
                      <>
                      <div className="col-md-6">
                        <button onClick={updateCustomerAccuntcor1} className="show_info_btn mt-4">Approved Request</button>
                    </div>
                    <div className="col-md-6">
                    <button onClick={DisapproveRequst} className="show_error_btn mt-4 ">Disapprove Request</button>

                    </div>
                     </> 
                      :messageRequest=='Request For Account Signatory(2) Change' ? 
                      <>
                      <div className="col-md-6">
                        <button onClick={updateCustomerAccuntcor2} className="show_info_btn mt-4">Approved Request</button>
                    </div>
                    <div className="col-md-6">
                    <button onClick={DisapproveRequst} className="show_error_btn mt-4 ">Disapprove Request</button>

                    </div>
                     </> 
                     :messageRequest=='Request For Account Signatory(3) Change' ? 
                     <>
                     <div className="col-md-6">
                       <button onClick={updateCustomerAccuntcor3} className="show_info_btn mt-4">Approved Request</button>
                   </div>
                   <div className="col-md-6">
                   <button onClick={DisapproveRequst} className="show_error_btn mt-4 ">Disapprove Request</button>

                   </div>

                    </>
                     :messageRequest=='Request change of Account Name' ? 
                     <>
                     <div className="col-md-6">
                       <button onClick={updateAccountname} className="show_info_btn mt-4">Approved Request</button>
                   </div>
                   <div className="col-md-6">
                   <button onClick={DisapproveRequst} className="show_error_btn mt-4 ">Disapprove Request</button>

                   </div>
                    </> 
                    
                    :message=='Requesting For Authorization On Valut' ? 
                    <>
                    <div className="col-md-6">
                      <button onClick={activateVault} className="show_info_btn mt-4">Approved Request</button>
                  </div>
                  <div className="col-md-6">
                  <button onClick={DisapproveRequst} className="show_error_btn mt-4 ">Disapprove Request</button>

                  </div>
                   </> :message=='Requesting For Valut De-activation' ? 
                    <>
                    <div className="col-md-6">
                      <button onClick={inActivateVault} className="show_info_btn mt-4">Approved Request</button>
                  </div>
                  <div className="col-md-6">
                  <button onClick={DisapproveRequst} className="show_error_btn mt-4 ">Disapprove Request</button>

                  </div>
                   </> :''}
                       
                      </div>

                    </div>


                  
                    
                    </Modal.Body>
                   
    </Modal>

      


    </div>
  )
}

export default Menu
