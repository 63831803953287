import {BrowserRouter ,Routes ,Route} from 'react-router-dom'
import React, { useEffect ,useState } from 'react'
import Home from './Components/Home';
import SearchMemberInfomation from './Components/SearchMemberInfomation';
import Login from "./Screens/Login";
import LoginRegistration from "./Screens/LoginRegistration";
import { useSelector } from "react-redux";
import Logout from "./Components/Logout";
import BankBranch from './bankAdministrator/BankBranch';
import Bank from './bankAdministrator/Bank';
import ProductServices from './bankAdministrator/Product_Services';
import Investment from './bankAdministrator/Investment';
import LoanProduct from './bankAdministrator/LoanProduct';
import ChageServicer from './bankAdministrator/Chage_Servicer';
import { useDispatch } from 'react-redux';
import CoporateAcc from './Components/CoporateAcc';
import OurProduct from './CustomerServiceRepresentatives/OurProduct';
import Membership from './CustomerServiceRepresentatives/Membership';
import IndividualAcc from './Components/IndividualAcc';
import Menu from './Components/Menu';
import Approvals from './Components/Approvals';
import ChartOfAccount from './Components/chartOfAccount';
import BankAccountServices from './Components/BankAccountServices';
import SearchByAccountNumber from './Components/SearchByAccountNumber';
import AccountDeposit from './Components/AccountDeposit';
import VaultSetup from './Components/VaultSetup';
import Vault from './Components/Vault';
import Wthdrawal from './Components/Wthdrawal';
import { useNavigate } from 'react-router-dom'
import { logout } from "./actions/userAction";
import CustomerAccount from './Components/CustomerAccount';
import Report from './Components/Report';
import LoanApplication from './Components/LoanApplication';
import LoanCommittee from './Components/LoanCommittee';
import LoanDisbursement from './Components/LoanDisbursement';
import AccountTransaction from './Components/AccountTransaction';

function App() {

  const dispatch=useDispatch()
  

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo }=userLogin

  const userProfileReducer = useSelector(state => state.userProfileReducer)
  const { userProfileInfo }=userProfileReducer

  const [loggedIn ,setLoggedIn]=useState(true)

  
  const navigate =useNavigate()

  const logoutHandler = ()=>{

   
    dispatch(logout())
    navigate('/')
   
    setLoggedIn(false)

}

  const checkForInactivity = ()=>{
    //get Expires Time Local Storage
    const expireTime =localStorage.getItem('expireTime')

    if(expireTime<Date.now()  && loggedIn==true){


      console.log('Log Out')

      navigate('/')
      
      logoutHandler()

    }
  }

  const updateExpireTime = ()=>{
    //set Expires Time to 5seconds
    const expireTime = Date.now() +  1200000236547892222222 // 120000

    //set Expires Time to local Storage
    localStorage.setItem('expireTime', expireTime);

    setLoggedIn(true)
  }

 
  useEffect(()=>{
    //set Initial Expires Time to local Storage
    updateExpireTime();

    //set event Listeners
    window.addEventListener('click',updateExpireTime)
    window.addEventListener('keypress',updateExpireTime)
    window.addEventListener('scroll',updateExpireTime)
    window.addEventListener('mousemove',updateExpireTime)
    window.addEventListener('mouseout',updateExpireTime)
    

    // clear up
    return () =>{
      window.removeEventListener('click',updateExpireTime)
      window.removeEventListener('keypress',updateExpireTime)
      window.removeEventListener('scroll',updateExpireTime)
      window.removeEventListener('mousemove',updateExpireTime)
      window.removeEventListener('mouseout',updateExpireTime)
    }
  },[])



  useEffect(()=>{

    //check for Inactive event 5 seconds

    

      const interval =setInterval(()=>{
        checkForInactivity();
      },5000)
  
  
      //clear interval on unmount
  
    return () => clearInterval(interval)

    
  

  },[loggedIn])


  return (
    
       <div className='appp'>

      {
         (() => {

          try {


            if(!userInfo){

              return(
  
                <Routes>
                <Route path="/" element={<Login/>}/>
                
              </Routes>
  
              )


            }else{


            if(userInfo.loginstatus !='Mobile Banker' ){

              return(
             
                <Menu>

                <Routes>
              
                  <Route path="/" element={<Home/>} />
                  <Route  path="/logout" element={<Logout/>} />
                  <Route path="/LoginRegistration" element={<LoginRegistration/>} />
                  <Route path="/SearchMemberInfomation" element={<SearchMemberInfomation/>} />
                  <Route path="/bankbranch" element={<BankBranch/>} />
                  <Route path="/bank" element={<Bank/>} />
                  <Route path="/Product_Services" element={<ProductServices/>} />
                  <Route path="/servicer" element={<ChageServicer/>} />
                  <Route path="/investment" element={<Investment/>} />
                  <Route path="/loanProduct" element={<LoanProduct/>} />
                  <Route path="/membership" element={<Membership/>} />
                  <Route path="/corporateaccount" element={<CoporateAcc/>} />
                  <Route path="/individualaccount" element={<IndividualAcc/>} />
                  <Route path="/approvals" element={<Approvals/>} />
                  <Route path="/chartofaccount" element={<ChartOfAccount/>} />
                  <Route path="/accountSerivces" element={<BankAccountServices/>} />
                  <Route path="/SearchByAccountNumber" element={<SearchByAccountNumber/>} />
                  <Route path="/accountdeposit" element={<AccountDeposit/>} />
                  <Route path="/VaultSetup" element={<VaultSetup/>} />
                  <Route path="/Vault" element={<Vault/>} />
                  <Route path="/accountWthdrawal" element={<Wthdrawal/>} />
                  <Route path="/CustomerAccount" element={<CustomerAccount/>} />
                  <Route path="/LoanApplication" element={<LoanApplication/>} />
                  <Route path="/Report" element={<Report/>} />
                  <Route path="/LoanCommittee" element={<LoanCommittee/>} />
                  <Route path="/LoanDisbursement" element={<LoanDisbursement/>} />
                  <Route path="/AccountTransaction" element={<AccountTransaction/>} />
                  
              </Routes>
              </Menu>
  
              )
          
  
            }else if(userInfo.loginstatus =='Mobile Banker'){

            
              logoutHandler()
              window.location.href = 'https://mobilebanker.startccu.com/';
             

            }
          }
  
          } catch (error) {
            
          }

         })()
      }
       </div>
    
  );
}

export default App;
