import React, { useState ,useEffect } from 'react'
import Modal from 'react-bootstrap/Modal';
import { useSelector } from "react-redux";
import Swal from 'sweetalert2'
import { updateUserProfile ,UserMenuItemsActions ,UserList } from '../actions/userAction';
import { useDispatch } from 'react-redux';
import { FaTwitter } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa6";
import { FaInstagramSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { Link } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { getUerProfileAction  } from '../actions/profileAction';
import ImageUploading from "react-images-uploading";
import { GrDocumentUpdate } from "react-icons/gr";
import { MdDeleteForever } from "react-icons/md";
import { updateUserProfileAction ,changelogin } from '../actions/profileAction';
import { CHANGE_USER_LOGOUT } from '../constants/profileConstants';
import Message from './Message';
import Loader from './Loader';
import { getBankAction } from '../actions/settingAction';
import { productList ,listBranch ,serviceList } from '../actions/settingAction';
import { memberList } from '../actions/memberAction';
import { chartOfAccountList } from '../actions/settingAction';
import profile from '../images/profile-img.jpg'
import { UserMessageListAction } from "../actions/approvalAction";
import useSound from 'use-sound';
import messagersent from '../images/messagersent.wav'
import { loadCustomerAccountNumberAction } from '../actions/customerAccountAction';
import { Disburese_load_loan, Loanpayment_schedule} from '../actions/customerAccountAction';
import moment from 'moment';
import {CustomerLoanAccountBalance , LoanRepaymentAccount ,SaveAccountTransaction} from '../actions/customerAccountAction';
import { ToWords } from 'to-words';
import { AccountAountServicerAction } from '../actions/customerAccountAction';
import { numberFormat } from './NumberFormat';
import { sms_disbursement } from './Message';
import { PRODUCT_RESET } from '../constants/settingConstants';
import { CUSTOMER_ACCOUNTS_RESET ,ACCOUNT_BALANCE_RESET } from '../constants/customerAccountConstants';


function Home() {

let Todaydate = moment().format('YYYY-MM-DD')
let approval_status=0


  const toWords = new ToWords({
          localeCode: 'en-US',
          converterOptions: {
            currency: true,
            ignoreDecimal: false,
            ignoreZeroCurrency: false,
            doNotAddOnly: false,
            currencyOptions: {
              // can be used to override defaults for the selected locale
              name: 'Ghana Cedis',
              plural: 'Ghana Cedis',
              symbol: '₹',
              fractionalUnit: {
                name: 'Pesewas',
                plural: 'Pesewas',
                symbol: '',
              },
            },
          },
        })
  

  const [playOn] = useSound(
    messagersent ,
    { volume: 0.99 }
  );

  const userProfileReducer = useSelector(state => state.userProfileReducer)
  const { userProfileInfo }=userProfileReducer
  

  const userProfileReducerUpdate = useSelector(state => state.userProfileReducerUpdate)
  const { userProfileInfoUpdate }=userProfileReducerUpdate


  const changeUserLoginReducer = useSelector(state => state.changeUserLoginReducer)
  const { changeUserInfo ,changeloading  ,success ,error}=changeUserLoginReducer


  const user_Message_Reducer = useSelector(state => state.user_Message_Reducer)
  const { usermessage ,usermessageLoading }=user_Message_Reducer


  const CustomerAccountReducer = useSelector(state => state.CustomerAccountReducer)
  const { customerAccounts ,customerAccountsLoading }=CustomerAccountReducer


  const productReducer = useSelector(state => state.productReducer)
  const { products ,loadingproduct }=productReducer

  const CustomerAccountBalanceReducer = useSelector(state => state.CustomerAccountBalanceReducer)
  const {accountbalance,accountbalanceLoading}=CustomerAccountBalanceReducer


  const accountAutoServiceReducer = useSelector(state => state. accountAutoServiceReducer)
   const {accountAutoServicerLoading ,accountAutoServicer }= accountAutoServiceReducer


    const accountTransactionReducer = useSelector(state => state.accountTransactionReducer)
   const {transactionLoading,transactionInfo ,depositsu}=accountTransactionReducer
   

   let dat=moment().format('MMMM Do YYYY, h:mm:ss a')
   let dat2 =moment().format('YYYY-MM-DD')

  useEffect(()=>{

    if(usermessage && usermessage.length >0){

      playOn()
     
    }

  },[user_Message_Reducer])


  const [images, setImages] = React.useState([]);
  
  const maxNumber = 69;
  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    setImages(imageList);

    if(imageList.length < 1){

      setImage(userProfileInfo.image)

    }else {

      setImage(imageList[0].data_url)
      console.log(imageList[0].data_url);

    }
   
  };


    const userLogin = useSelector(state => state.userLogin)
    const { userInfo }=userLogin

   
    const [abount ,setAbount]=useState('')
    const [address ,setAddress]=useState('')
    const [Phone ,setPhone] =useState('')
    const [email ,setEmail]=useState('')
    const [image ,setImage]=useState('')

    const [twitter ,settwitter]=useState('')
    const [facebook ,setfacebook]=useState('')
    const [instagram ,setinstagram]=useState('')
    const [linkedin ,setlinkedin]=useState('')

    const [currentPassword ,setCurrentPassword]=useState()


   let is_staff =1 


   
    const [password ,setPassword] = useState()
    const [confirmPassword ,setConfirmPassword] = useState()

    const [newPassword ,setNewPassword] = useState()
    const [renewPassword ,setrenewPassword] =useState()
    
    const dispatch=useDispatch()

  
    const successMessage=(message) => {
      Swal.fire({
        position: "center",
        icon: "success",
        title: message ,
        showConfirmButton: false,
        timer: 1500
      });
    }


    const errorsMessage=(message) => {
      Swal.fire({
        position: "center",
        icon: "error",
        title: message ,
        showConfirmButton: false,
        timer: 1500
      });
    }

    const [showPasswordchange ,SetshowPasswordchange] =useState(false)

    const handalShowPasswordChange = () => {
        SetshowPasswordchange(true)
    }

    const handalclosePasswordChange = () => {

        SetshowPasswordchange(false)
    }



  useState(() => {
        if(!userInfo.is_staff){

            handalShowPasswordChange()
        }
} )



const changePasswordFirstTime=(e)=>{
    e.preventDefault();

    if(password !=confirmPassword){

        Swal.fire({
            icon: 'error',
            title: 'Password Mismatch',
            text: ' ',
            footer: 'Error Please Fix This'
          })

    }else {

        dispatch(updateUserProfile({

            'id':userInfo._id,
            'is_staff':is_staff,
            'password':password
          
        }))

        successMessage('Password Successfully Change')

        setPassword('')
        setConfirmPassword('')

        handalclosePasswordChange()


    }
}

 let save_aproval="True"
 let Status="False"

useEffect(()=>{
  
dispatch(getUerProfileAction(userInfo.id))
dispatch(getBankAction(1))
dispatch(listBranch())
dispatch(serviceList())
dispatch(memberList())
dispatch(UserMenuItemsActions(userInfo.id))
dispatch(chartOfAccountList())
dispatch(UserMessageListAction(userInfo.username ,approval_status))
dispatch(loadCustomerAccountNumberAction(save_aproval))
dispatch(UserList())
dispatch(Disburese_load_loan(save_aproval))
dispatch(Loanpayment_schedule(Status))
dispatch(CustomerLoanAccountBalance())

},[userInfo ,userProfileInfoUpdate])


const [getdayDedactionState , setgetdayDedactionState]=useState([])


useEffect(()=>{

if( products && products.length >0){

  const getdayDedaction =(products.filter(obj=>obj.date <=Todaydate))

  setgetdayDedactionState(getdayDedaction)

}

},[productReducer])


let repaymentAmount=0

useEffect(()=>{

  if(products && customerAccounts && accountbalance){

  if(products.length >0 &&  customerAccounts.length >0  && accountbalance.length>0){
    
    for(let x=0 ;x<getdayDedactionState.length ;x++){

      const getaccountdetails=accountbalance.find(obj=>obj.account_number==getdayDedactionState[x].account_number)
      const loanAccountDetals=customerAccounts.find(obj=>obj.Acc==getdayDedactionState[x].account_number)

      if( (Number(getdayDedactionState[x].instalment)) > (Number(getaccountdetails.account_balance) - Number(loanAccountDetals.CashLien) )){
        

        
        let Status=0
        let payment_narration='Overdue Payment'
        dispatch(LoanRepaymentAccount({trans_code:getdayDedactionState[x].trans_code , Status , payment_narration }))
     
      }else {

        dispatch(AccountAountServicerAction(getdayDedactionState[x].account_number ,save_aproval))
       
        repaymentAmount=Number(getdayDedactionState[x].instalment).toFixed(2)

         let words = toWords.convert(getdayDedactionState[x].instalment, { currency: true });

         dispatch(SaveAccountTransaction(0.00 ,dat ,repaymentAmount , repaymentAmount  ,userProfileInfo.branch ,getdayDedactionState[x].account_number
          ,'Loan Repayment' ,dat2 ,getdayDedactionState[x].account_name, '-' ,userInfo.username ,'System','-','-','-','-' ,0 , 0 ,0 ,0 ,0 ,0 ,0 ,0, 0 , 0 ,0 ,0 , 0 ,words ))

        let Status=1
        let payment_narration='Paid'

        dispatch(LoanRepaymentAccount({trans_code:getdayDedactionState[x].trans_code , Status , payment_narration }))

      }

    }

    for(let i=0 ;i<customerAccounts.length ; i++){

      console.log(customerAccounts[i])

    }


    dispatch({type:CUSTOMER_ACCOUNTS_RESET})
    dispatch({type:PRODUCT_RESET})
    dispatch({type:ACCOUNT_BALANCE_RESET})

    
  }
}
},[products ,customerAccounts ,accountbalance])

const [SMS ,setSMS]=useState('')

useEffect(()=>{
  
    const smss=accountAutoServicer.find(obj=>obj.service_name =='SMS alert on transactions')
  
    if(smss){
      setSMS(smss)
    }else {
      setSMS(smss)
    }
  
    console.log(smss)
  
  },[accountAutoServiceReducer])


useEffect(()=>{
  
  
      if(depositsu){


         if(SMS){
           
            let tel= SMS.tel
            console.log(tel)
            let cname=SMS.account_name
            let acno=SMS.account_number.substr(4, 5);
            let drcr='Credited'
            let desc='Loan Repayment'
            let dat =moment().format('YYYY-MM-DD ,h:mm:ss a')
        
            var amountt=numberFormat(repaymentAmount).split('$')[1]
          
           // sms_disbursement(tel ,cname,acno,drcr,amountt,desc,dat)
          }
        
        
         setSMS('')
         repaymentAmount=0
      
      }
     
  
    },[accountTransactionReducer])



useEffect(()=>{

  if(success){

  
  dispatch(updateUserProfile({
    'id':userInfo._id,
    'is_staff':is_staff,
    'password':newPassword
}))

successMessage('Password successfully Changed')
setCurrentPassword('')
setNewPassword('')
setrenewPassword('')

//navigate('/logout')

}

dispatch({type : CHANGE_USER_LOGOUT})

},[changeUserInfo])


const saveProfile=(e)=>{
  e.preventDefault();
  try {
    
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
      confirmButton: "btn btn-success mx-4",
      cancelButton: "btn btn-danger"
      },
      buttonsStyling: false
  });
  swalWithBootstrapButtons.fire({
      title: "Comfirm Profile Update",
      text: "Are you sure you want to update You Profile information ?",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      position:"center"
   

  }).then((result) => {

      if (result.isConfirmed) {

          
        dispatch(updateUserProfileAction({trans_code:userProfileInfo.trans_code ,
          abount,
          address ,
          Phone ,
          email ,
          image ,
          twitter,
          facebook ,
          instagram ,
          linkedin
    
        }))


      successMessage('Profile Successfully Updated')
      
          

      } else if (result.dismiss === Swal.DismissReason.cancel) {

       // clearGrade();
      }
      
  });


  } catch (error) {
    
  }


}

const changepassword =(e)=>{

  e.preventDefault()

  if(newPassword !=renewPassword){

    Swal.fire({
        icon: 'error',
        title: 'Password Mismatch',
        text: 'New Passwords do not Match',
        footer: 'Error Please Fix This'
      })

}else {

  dispatch(changelogin(userInfo.username ,currentPassword))

}

}



  return (

    <div>

      
<div className='solcon overflow-auto vh-100'>

<div className='container'>

{customerAccountsLoading && <Loader/>}
{loadingproduct && <Loader/>}
{accountbalanceLoading && <Loader/>}


<div className="pagetitle">
  <h1>Profile</h1>
  <nav>
    <ol className="breadcrumb">
      <li className="breadcrumb-item">Home</li>
      <li className="breadcrumb-item">Users</li>
      <li className="breadcrumb-item active">Profile</li>
    </ol>
  </nav>
</div>

<div className="section profile" >
    <div className='row'>
      <div className="col-xl-4">
      <div className="card">
        <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">

          {
            userProfileInfo ? 
            userProfileInfo.image=='' ?  <img src={profile} alt="Profile" className="rounded-circle"/> :  
            <img src={userProfileInfo.image} alt="Profile" className="rounded-circle"/> 
            :''
           
          }
         
          <h2>{userInfo.first_name}</h2>
          <h3>{userInfo.last_name}</h3>
          <div className="social-links mt-2">
            <Link  className="twitter"><FaTwitter/></Link>
            <Link  className="facebook"><FaFacebook/></Link>
            <Link className="instagram"><FaInstagramSquare/></Link>
            <Link className="linkedin"><FaLinkedin/></Link>
          </div>
        </div>
      </div>
        

      </div>

      <div className="col-xl-8">

      <div className='card'>
        <div className='card-body'>
        
        <Tabs
      defaultActiveKey="Overview"
      id="justify-tab-example"
      className="mb-3"
      justify
    >

      <Tab eventKey="Overview" title="Overview">
      

      <div className="tab-pane fade show active profile-overview ms-5 me-5" id="profile-overview">
              <h5 className="card-title">About</h5>
              <p className="small fst-italic">{ userProfileInfo ? userProfileInfo.abount:''}</p>

              <h5 className="card-title">Profile Details</h5>

              <div className="row">
                <div className="col-lg-3 col-md-4 label ">Name</div>
                <div className="col-lg-9 col-md-8">{userProfileInfo ? userInfo.first_name :''}</div>
              </div>

              

              <div className="row">
                <div className="col-lg-3 col-md-4 label">Login Status</div>
                <div className="col-lg-9 col-md-8">{userInfo.last_name}</div>
              </div>

              

              <div className="row">
                <div className="col-lg-3 col-md-4 label">Address</div>
                <div className="col-lg-9 col-md-8">{userProfileInfo ? userProfileInfo.address:''}</div>
              </div>

              <div className="row">
                <div className="col-lg-3 col-md-4 label">Phone</div>
                <div className="col-lg-9 col-md-8">{userProfileInfo ? userProfileInfo.phone:''}</div>
              </div>

              <div className="row">
                <div className="col-lg-3 col-md-4 label">Email</div>
                <div className="col-lg-9 col-md-8">{userProfileInfo ? userProfileInfo.email:''}</div>
              </div>

              <h5 className="card-title">Social Media Handles</h5>

              <div className="row">
                <div className="col-lg-3 col-md-4 label">Twitter Profile</div>
                <div className="col-lg-9 col-md-8">{userProfileInfo ? userProfileInfo.twitter :''}</div>
              </div>


              <div className="row">
                <div className="col-lg-3 col-md-4 label">Facebook Profile</div>
                <div className="col-lg-9 col-md-8">{userProfileInfo ? userProfileInfo.facebook : ''}</div>
              </div>

              <div className="row">
                <div className="col-lg-3 col-md-4 label">Instagram Profile</div>
                <div className="col-lg-9 col-md-8">{userProfileInfo ? userProfileInfo.instagram :''}</div>
              </div>

              <div className="row">
                <div className="col-lg-3 col-md-4 label">Linkedin Profile</div>
                <div className="col-lg-9 col-md-8">{userProfileInfo ? userProfileInfo.linkedin : ''}</div>
              </div>


            </div>
      </Tab>


      <Tab eventKey="Edit Profile" title="Edit Profile" className='ms-4 me-4' >

      <div className="row mb-3">
                  <label for="profileImage" className="col-md-4 col-lg-3 col-form-label">Profile Image</label>
                  <div className="col-md-8 col-lg-9">
                  <ImageUploading 
                    multiple
                    value={images}
                    onChange={onChange}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                    acceptType={["jpg"]}

                >
                    {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps
                    }) => (
                    // write your building UI
                    <div className="upload__image-wrapper">
                        {
                            images.length ==1 ? '' :(
                                <button className='btn-class'
                                style={isDragging ? { color: "red" } : null}
                                onClick={onImageUpload}
                                {...dragProps}
                                >
                                Browse For Profile Image
                                </button>
                            )

                        }
                       
                        &nbsp;
                       
                        {imageList.map((image, index) => (
                        <div key={index} className="image-item">
                          
                            <img src={image.data_url} alt="" width="120" />
                            <div className="image-item__btn-wrapper">
                            <button className='edit-btn '  onClick={() => onImageUpdate(index)}>  <GrDocumentUpdate/>  </button>
                            <button className='del-btn ms-3'  onClick={() => onImageRemove(index)}>  <MdDeleteForever/>  </button>
                            </div>
                        </div>
                        ))}
                    </div>
                    )}
                </ImageUploading>
                  </div>
                </div>


             <form  className='me-5'>
              
                <div className="row mb-3">
                  <label for="about" className="col-md-4 col-lg-3 col-form-label">About</label>
                  <div className="col-md-8 col-lg-9">
                    <textarea name="about" className="form-control" value={abount} onChange={(e)=>setAbount(e.target.value)}></textarea>
                  </div>
                </div>

              
                <div className="row mb-3">
                  <label for="Address" className="col-md-4 col-lg-3 col-form-label">Address</label>
                  <div className="col-md-8 col-lg-9">
                    <input name="address" type="text" className="form-control" onChange={(e)=>setAddress(e.target.value)} value={address}/>
                  </div>
                </div>

                <div className="row mb-3">
                  <label for="Phone" className="col-md-4 col-lg-3 col-form-label">Phone</label>
                  <div className="col-md-8 col-lg-9">
                    <input name="phone" type="text" className="form-control" id="Phone" onChange={(e)=>setPhone(e.target.value)}  value={Phone}/>
                  </div>
                </div>

                <div className="row mb-3">
                  <label for="Email" className="col-md-4 col-lg-3 col-form-label">Email</label>
                  <div className="col-md-8 col-lg-9">
                    <input name="email" type="email" className="form-control" onChange={(e)=>setEmail(e.target.value)} value={email}/>
                  </div>
                </div>

                <div className="row mb-3">
                  <label for="Twitter" className="col-md-4 col-lg-3 col-form-label">Twitter Profile</label>
                  <div className="col-md-8 col-lg-9">
                    <input name="twitter" type="text" className="form-control" id="Twitter" onChange={(e)=>settwitter(e.target.value)} value={twitter}/>
                  </div>
                </div>

                <div className="row mb-3">
                  <label for="Facebook" className="col-md-4 col-lg-3 col-form-label">Facebook Profile</label>
                  <div className="col-md-8 col-lg-9">
                    <input name="facebook" type="text" className="form-control" id="Facebook" onChange={(e)=>setfacebook(e.target.value)} value={facebook}/>
                  </div>
                </div>

                <div className="row mb-3">
                  <label for="Instagram" className="col-md-4 col-lg-3 col-form-label">Instagram Profile</label>
                  <div className="col-md-8 col-lg-9">
                    <input name="instagram" type="text" className="form-control" id="Instagram" onChange={(e)=>setinstagram(e.target.value)} value={instagram}/>
                  </div>
                </div>

                <div className="row mb-3">
                  <label for="Linkedin" className="col-md-4 col-lg-3 col-form-label">Linkedin Profile</label>
                  <div className="col-md-8 col-lg-9">
                    <input name="linkedin" type="text" className="form-control" id="Linkedin" onChange={(e)=>setlinkedin(e.target.value)}  value={linkedin}/>

                    <button onClick={saveProfile} className="btn btn-primary mt-4 mb-5">Save Changes</button>
                  </div>
                </div>

                
                 
               
              </form>
      </Tab>


      <Tab eventKey="Settings" title="Settings">
            
              <form>

          <div className="row mb-3">
            <label for="fullName" className="col-md-4 col-lg-3 col-form-label">Email Notifications</label>
            <div className="col-md-8 col-lg-9">
              <div className="form-check">
                <input className="form-check-input" type="checkbox" id="changesMade" checked/>
                <label className="form-check-label" for="changesMade">
                  Changes made to your account
                </label>
              </div>
              <div className="form-check">
                <input className="form-check-input" type="checkbox" id="newProducts" checked/>
                <label className="form-check-label" for="newProducts">
                  Information on new products and services
                </label>
              </div>
              <div className="form-check">
                <input className="form-check-input" type="checkbox" id="proOffers"/>
                <label className="form-check-label" for="proOffers">
                  Marketing and promo offers
                </label>
              </div>
              <div className="form-check">
                <input className="form-check-input" type="checkbox" id="securityNotify" checked disabled/>
                <label className="form-check-label" for="securityNotify">
                  Security alerts
                </label>
              </div>
            </div>
          </div>

          <div className="text-center">
            <button type="submit" className="btn btn-primary">Save Changes</button>
          </div>
          </form>
      </Tab>


      <Tab eventKey="Change Password" title="Change Password" className='ms-4 me-4'>

      {changeloading && <Loader/>}
      {error && <Message variant={'danger'}>{error}</Message>}
            <form onSubmit={changepassword}>
  
              
  
            <div className="row mb-3">
              <label for="currentPassword" className="col-md-4 col-lg-3 col-form-label">Current Password</label>
              <div className="col-md-8 col-lg-9">
                <input name="password" type="password" className="form-control" required value={currentPassword} onChange={(e)=>setCurrentPassword(e.target.value)}/>
              </div>
            </div>

            <h4 className='mb-3'>New Password</h4>

            <div className="row mb-3">
              <label for="newPassword" className="col-md-4 col-lg-3 col-form-label">New Password</label>
              <div className="col-md-8 col-lg-9">
                <input name="newpassword" type="password" required className="form-control" value={newPassword} onChange={(e)=>setNewPassword(e.target.value)}/>
              </div>
            </div>

            <div className="row mb-3">
              <label for="renewPassword" className="col-md-4 col-lg-3 col-form-label">Re-enter New Password</label>
              <div className="col-md-8 col-lg-9">
                <input name="renewpassword" type="password"  required className="form-control" value={renewPassword} onChange={(e)=>setrenewPassword(e.target.value)}/>

                <button type="submit" className="btn btn-primary mt-4">Change Password</button>
              </div>
            </div>

           
             
           
            </form>
      </Tab>


    </Tabs>
    
        </div>
      </div>
        
      </div>

    </div>

</div>
</div>
</div>


        <Modal size='lg'  show={showPasswordchange} onHide={handalclosePasswordChange} backdrop="static" keyboard={false}>

            <Modal.Body >

            
            <div className='container '>

                <div className='row content'>

                

                    <div className='col-md-12'>

                        <h3 className='signin-text '>On You First Login .Please Change Your Password</h3>

                        <form  onSubmit={changePasswordFirstTime}>
                        
                            <div className='form-group mt-4 pb-1'>
                                <label for='username'>New Password:</label>
                                <input required type='password' name='username' className='form-control' value={password} onChange={(e)=>setPassword(e.target.value)} />
                            </div>
                            
                            <div className='form-group mt-3'>
                                <label for='password'>confirm Password</label>
                                <input required type='password' name='password' className='form-control' value={confirmPassword} onChange={(e)=>setConfirmPassword(e.target.value)} />
                            </div>
                            <button type='submit' className='btn-className mt-4'>Change Password</button>
                        </form>

                    </div>

                </div>

            </div>
                
            </Modal.Body>
        
        </Modal>
      
    </div>
  )
}

export default Home
