import React, { useEffect } from 'react'
import { useDispatch ,useSelector } from 'react-redux';
import { useState } from 'react';
import { UserTransactionAccountHistory } from '../actions/customerAccountAction';
import moment from 'moment';
import { cash } from './NumberFormat';
import DataTable from 'react-data-table-component'
import { IoSearchOutline } from "react-icons/io5";
import { TbFileReport } from "react-icons/tb";

function AccountTransaction() {

    const CustomerAccountTrancationReducer = useSelector(state => state. CustomerAccountTrancationReducer)
    const {Account_histoeyLoading ,Account_histoey }= CustomerAccountTrancationReducer

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo }=userLogin
    

    const userProfileReducer = useSelector(state => state.userProfileReducer)
    const { userProfileInfo } = userProfileReducer

    const dispatch=useDispatch()
    const [p1 ,setp1]=useState('')
    const [p2 ,setp2]=useState('')
    
    const [uniqueUser ,setUniqueUser]=useState('')


useEffect(()=>{

    dispatch(UserTransactionAccountHistory())

},[dispatch])


useEffect(()=>{

    setUniqueUser(Account_histoey.filter( (item, pos, self) => self.findIndex(v => v.user === item.user) === pos))
    
},[CustomerAccountTrancationReducer])


const [dateData , setdateData]=useState('')
const [TransUser , setTransUser]=useState('')

const loadTransByDate=()=>{

    let dat_1 = moment(p1).format('YYYY-MM-DD')
    let dat_2 = moment(p2).format('YYYY-MM-DD')

    setdateData(Account_histoey.filter(obj=>obj.dat2 >= dat_1 && obj.dat2<=dat_2))

}


 const historyColumns =[
 
    {
     name:'#' ,
     selector:row=>row.trans_code 
     ,wrap:true 
  
    } ,
  
     {
      name:'Date' ,
      selector:row=>row.dat
      ,wrap:true
     },

     {
        name:'Description',
        selector:row=> row.des 
        ,wrap:true ,
        sortable:true
       },
  
     {
      name:'Deposit',
      selector:row=> cash(row.cr) 
      ,wrap:true ,
      sortable:true
     } ,
  
     {
      name:'Withdrawal',
      selector:row=> cash(row.dr) 
      ,wrap:true ,
      sortable:true
     },
  
     {
      name:'Transaction By',
      selector:row=> row.trans_by 
      ,wrap:true ,
      sortable:true
     },
  
     {
      name:'Tel#',
      selector:row=> row.tel 
      ,wrap:true ,
      sortable:true
     },
   
     {
      name:'User',
      selector:row=> row.user 
      ,wrap:true ,
      sortable:true
     },
  
  ]

const loaduserTrans=(e)=>{

    setTransUser(e.target.value)
    let dat_1 = moment(p1).format('YYYY-MM-DD')
    let dat_2 = moment(p2).format('YYYY-MM-DD')

    setdateData(Account_histoey.filter(obj=>obj.dat2 >= dat_1 && obj.dat2<=dat_2 && obj.user==e.target.value) )

}


const UsersTreasureBalances=()=>{

    window.open(`http://localhost/report/Account Transaction_balance.html?user=${uniqueUser}&dat_1=${p1}&dat_2=${p2}&branch=${userProfileInfo.branch}`, "_blank");
  } 
  
     

  return (

    <div className='solcon overflow-auto vh-100' >
        <div className=''>

        <div>Account Transaction</div>

                <div className='card'>

                     <div className='card-body'>
                        <div className='row'>
                            <div className='col-md-2'>
                            <label className='lable' for='Utility'>First Period</label>
                            <input name="Name" type="date" className="form-control mb-3" value={p1} onChange={(e)=>setp1(e.target.value)} />
                            </div>

                            <div className='col-md-2'>
                            <label className='lable' for='Utility'>Second Period</label>
                            <input name="Name" type="date" className="form-control mb-3" value={p2} onChange={(e)=>setp2(e.target.value)} />
                            </div>

                            <div className='col-md-2'>

                            <button onClick={loadTransByDate}  className='show_info_btn mt-3 w-100'><IoSearchOutline /> Search</button>
                            </div>

                            <div className='col-md-2'>

                            <button onClick={UsersTreasureBalances}  className='show_info_btn mt-3 w-100'><TbFileReport /> Report</button>
                            </div>

                            <div className='col-md-3'>
                            <label className='lable' for='Utility'>Transaction User</label>
                            <select required  className='form-control mb-4' value={TransUser} onChange={loaduserTrans} >
                            <option selected value="Select" >Select</option>
                                
                                {uniqueUser && uniqueUser.map((user)=>(
                                    <option value={user.user}>{user.user}</option>
                                ))}

                             </select>

                            </div>

                        </div>

                        <div className='card'>
                        <DataTable className='rdt_Table' columns={historyColumns}  data={dateData}   
                            selectableRowsHighlight 
                            highlightOnHover
                            fixedHeader
                            pagination 
                            paginationPerPage={18}
                            //onSelectedRowsChange={getSelectedSubjects}
                            // selectableRows  
                            //selectableRowSelected={getPatientInfo} 
                            //</div> onRowClicked={getPatientInfo}
                            responsive={true}
                            >
            
                        </DataTable>
                      </div>

                     </div>
                     
                    
                </div>
           

        </div>
        </div>
        
  )
}

export default AccountTransaction